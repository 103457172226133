import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { CircularProgress, IconButton, Box, Grid, Button, Divider } from "@mui/material";

import { useDropzone } from "react-dropzone";
import { isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";

import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "../../assets/downloadIcon";
import CensusFile from "../../assets/census_template.xlsx";

import {
  getEmployeeList,
  deleteEmployee,
  getEmployeeListFilter,
  onSaveCensusFile,
  onResetCensusUpload,
  onReUploadCensusUpload,
} from "../../Utils/apiUtils";
import { escapeRegex } from "../../Utils/helperUtils";
import { CensusFileResponse, CensusFileInterface } from "../../Utils/interfaces";

import CustomAlert from "../Elements/CustomAlert";
import CustomDialog from "../Elements/CustomDialog";
import SearchBar from "../Elements/SearchBar/Searchbar";
import FileUpload from "./FileUpload";
import CustomTable from "./CustomTable";
import EmployeeTabs from "./EmployeeTabs";
import CensusNoEmployee from "./CensusNoEmployee";

import {
  FileUploadWrapper,
  CensusFileHeaderContainer,
  CensusFileTitle,
  CensusFileSubTitle,
  CensusFileReUploadButton,
  CensusFileReUploadIcon,
  CensusFileReUploadText,
  CensusFileUploadTitle,
  CensusFileUploadSubtitle,
  CensusFileUploadTemplateContainer,
  CensusFileUploadTemplateButtonText,
  CensusFilePaperContainer,
  CensusFileUploadButton,
  CensusFileUploadButtonText,
  FilesUploadContainer,
  CensusFileErrorText,
} from "./CensusEmployeeList.style";

type employeeDetails = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  contactNumber: string;
  state: string;
  companyId: string;
  companyName: string;
  companyCode: string;
  journeys: string;
  status: string;
  personalEmailCode: string;
  terminationDate?: string;
};

type CustomConfirmProps = {
  isOpen: boolean;
  title: string;
  body: string;
  onConfirm?: (event: Event | React.SyntheticEvent<any, Event>) => void;
};

type PageResult = {
  items: Array<employeeDetails>;
  lastEvaluatedKey: string;
  isForward: boolean;
  limitQuery: number;
  itemsCount: number;
};

interface EmployeeListRequest {
  companyId: string;
  limitRequest: number;
  filter: string[];
  isForward: boolean;
  lastEvaluatedKey: string | null;
}

const CensusEmployeesList = () => {
  const [employeeList, setEmployeeList] = useState<Array<employeeDetails>>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isEmployeesLoading, setEmployeesLoading] = useState(false);
  const [alert, setAlert] = useState<any>({});
  const [confirmDialog, setConfirmDialog] = useState<CustomConfirmProps>({
    isOpen: false,
    title: "",
    body: "",
  });

  const location: any = useLocation();
  const [tags, setTags] = useState<string[]>([]);
  const [callGetEmployeeList, setCallGetEmployeeList] = useState(false);
  const [isReUploading, setIsReUploading] = useState(false);
  const [isLoadingChanges, setIsLoadingChanges] = useState(false);
  const [censusFilesResponse, setCensusFilesResponse] = useState<CensusFileResponse[]>([]);
  const [files, setFiles] = useState<CensusFileInterface[]>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const acceptedCensusFiles = acceptedFiles.map((item) => ({ file: item, id: uuidv4() }));
    setFiles((_) => [...acceptedCensusFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    },
    maxFiles: 1
  });


  const showFilesError = () => {
    return (
      <CensusFileErrorText>
        {!!fileRejections.length && (
          <ul>
            <li>
              {
                fileRejections[0].errors.map(error => error.message)
              }
            </li>
          </ul>
        )}
      </CensusFileErrorText>
    )
  }

  useEffect(() => {
    const resetCensusUpload = async () => {
      const response = await onResetCensusUpload({ companyId: location.state.id });

      if (response.type === " error") {
        setAlert({
          open: true,
          type: "error",
          message: `Unexpected Error while uploading the company. Please reload the page.`,
        });
      }
    };

    if (isEmpty(employeeList)) {
      resetCensusUpload();
    }
  }, [employeeList]);

  useEffect(() => {
    setEmployeesLoading(true);
    const data: EmployeeListRequest = {
      companyId: location.state.id,
      limitRequest: rowsPerPage,
      filter: tags,
      isForward: true,
      lastEvaluatedKey: null,
    };

    handleGetEmployeeListFilter(data);
  }, [location.state.id, tags, rowsPerPage, callGetEmployeeList]);

  useEffect(() => {
    if (!isEmpty(censusFilesResponse)) {
      const errorFiles: string[] = [];

      censusFilesResponse.forEach((item) => {
        if (!item.processed) {
          return;
        }

        if (item.errorEmployeeProcess.listCount > 0) {
          const errorFile = files.find((file) => file.id === item.fileId);

          if (errorFile) {
            errorFiles.push(errorFile?.file.name);
          }
        }
      });

      if (!isEmpty(errorFiles)) {
        setAlert({
          open: true,
          type: "error",
          message: `During the upload process an error was identified in "${errorFiles.join(
            ", "
          )}".  Please review the Error column for specific details.`,
        });
      }
    }
  }, [censusFilesResponse]);

  const handleGetEmployeeListFilter = (requestData: EmployeeListRequest) => {
    getEmployeeListFilter(requestData).then((res: PageResult) => {
      setEmployeeList(res.isForward ? res.items : res.items.reverse());
      setCount(res.itemsCount);
      setEmployeesLoading(false);
    });
  };

  const handleSearch = (searchTags: string[]) => {
    setTags(searchTags);
    setPage(0);
  };

  const handleChangePage = (newPage: number) => {
    let isForward = true;
    setPage((prevPage) => {
      isForward = prevPage < newPage;
      return newPage;
    });

    setEmployeesLoading(true);
    const data: EmployeeListRequest = {
      companyId: location.state.id,
      limitRequest: rowsPerPage,
      filter: tags,
      isForward,
      lastEvaluatedKey: isForward
        ? btoa(
          JSON.stringify({
            pk: { BS: [], L: [], M: {}, NS: [], S: location.state.id, SS: [] },
            sk: { BS: [], L: [], M: {}, NS: [], S: employeeList.reverse()[0].id, SS: [] },
          })
        )
        : btoa(
          JSON.stringify({
            pk: { BS: [], L: [], M: {}, NS: [], S: location.state.id, SS: [] },
            sk: { BS: [], L: [], M: {}, NS: [], S: employeeList[0].id, SS: [] },
          })
        ),
    };

    handleGetEmployeeListFilter(data);
  };

  const highlightText = (text: string): React.ReactNode => {
    if (tags.length === 0) {
      return text;
    }

    const escapedTags = tags.map(escapeRegex);
    const parts = text.split(new RegExp(`(${escapedTags.join("|")})`, "gi"));
    return parts.map((part, index) =>
      escapedTags.some((tag) => new RegExp(tag, "i").test(part)) ? <b key={index}>{part}</b> : part
    );
  };

  const handleDelete = (company: string, id: string) => {
    const request = {
      companyId: company,
      employeeId: id,
    };
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    setEmployeesLoading(true);
    deleteEmployee(request).then((res) => {
      setAlert({ type: res.type, message: res.message, open: true });
      setEmployeesLoading(true);
      getEmployeeList(location.state.id).then((res: any) => {
        setEmployeeList(res);
        setEmployeesLoading(false);
      });
      setEmployeesLoading(false);
    });
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({});
  };

  const handleReUpload = async () => {
    await onReUploadCensusUpload({ companyId: location.state.id });
    setIsReUploading(true);
  };

  const handleCancel = async () => {
    setFiles([]);
    setCensusFilesResponse([]);
    setIsReUploading(false);

    await onResetCensusUpload({ companyId: location.state.id });
  };

  const handleOnSave = async () => {
    setIsLoadingChanges(true);
    const requestData = files.map((item) => {
      return {
        companyId: location.state.id,
        FileId: item.id,
        FileName: files.find((file) => file.id === item.id)?.file?.name,
      };
    });

    const response = await onSaveCensusFile(requestData);
    if (response.type === "success") {
      setAlert({
        open: true,
        severity: "success",
        message: `Company Users has been added successfully`,
      });

      setFiles([]);
      setCensusFilesResponse([]);
      setIsReUploading(false);

      setCallGetEmployeeList((prev) => !prev);
    }
    setIsLoadingChanges(false);
  };

  const columns = [
    {
      header: "First Name",
      render: (rowItem: employeeDetails) => {
        return highlightText(rowItem.firstname);
      },
    },
    {
      header: "Last Name",
      render: (rowItem: employeeDetails) => {
        return highlightText(rowItem.lastname);
      },
    },
    {
      header: "Username",
      render: (rowItem: employeeDetails) => {
        return highlightText(rowItem.email || rowItem.personalEmailCode || "");
      },
    },
    {
      header: "Termination Date",
      render: (rowItem: employeeDetails) => {
        return rowItem?.terminationDate || "";
      },
    },
    {
      header: "Actions",
      action: (row: employeeDetails) => (
        <IconButton
          sx={{ color: "#B30303", width: "40px", minWidth: "0" }}
          color="error"
          aria-controls="ringtone-menu"
          size="small"
          onClick={() => {
            handleDelete(location.state.id, row.id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const renderHeader = () => {
    if (isEmpty(employeeList) || isReUploading) {
      return (
        <Grid>
          <CensusFileUploadTitle variant="h2">
            {`${isReUploading ? "Re-upload " : ""}Employee List`}
          </CensusFileUploadTitle>
          <CensusFileUploadTemplateContainer>
            <CensusFileUploadSubtitle variant="body1">
              Download the <b>Employee List Template</b> file, and upload your employee list
            </CensusFileUploadSubtitle>
            <Button
              sx={{ px: "6px !important", py: "8px !important", ".MuiButton-startIcon": { mr: "2px", ml: "-1" } }}
              href={CensusFile}
              download="census_file_template.xlsx"
              startIcon={<DownloadIcon sx={{ fontSize: "16px" }} />}
            >
              <CensusFileUploadTemplateButtonText>
                &nbsp;&nbsp;Employee List Template
              </CensusFileUploadTemplateButtonText>
            </Button>
          </CensusFileUploadTemplateContainer>
        </Grid>
      );
    }

    return (
      <CensusFileHeaderContainer>
        <div>
          <CensusFileTitle>Employees</CensusFileTitle>
          <CensusFileSubTitle>Employees currently enrolled in the platform</CensusFileSubTitle>
        </div>
        <Grid>
          <CensusFileReUploadButton variant="contained" onClick={handleReUpload} startIcon={<CensusFileReUploadIcon />}>
            <CensusFileReUploadText variant="body2">&nbsp;Re-upload</CensusFileReUploadText>
          </CensusFileReUploadButton>
        </Grid>
      </CensusFileHeaderContainer>
    );
  };

  return (
    <CensusFilePaperContainer elevation={2}>
      {alert.open && (
        <CustomAlert open={alert.open} message={alert.message} severity={alert.type} handleClose={handleClose} />
      )}
      {renderHeader()}
      {(isReUploading || isEmpty(employeeList)) && (
        <>
          <Grid sx={{ display: "flex" }}>
            <FileUploadWrapper isDragActive={isDragActive}>
              <Grid {...getRootProps()} sx={{ display: "flex", alignItems: "center" }}>
                <input {...getInputProps()} />
                <CensusFileUploadButton size="small" variant="outlined">
                  Upload files
                </CensusFileUploadButton>
                <CensusFileUploadButtonText>Or drop files</CensusFileUploadButtonText>
              </Grid>
              <FilesUploadContainer>
                {showFilesError()}
                {files.map((file) => {
                  return (
                    <FileUpload
                      key={`${file.id}`}
                      companyId={location.state.id}
                      file={file}
                      setFiles={setFiles}
                      setCensusFilesResponse={setCensusFilesResponse}
                      setAlert={setAlert}
                      setIsLoadingChanges={setIsLoadingChanges}
                    />
                  );
                })}
              </FilesUploadContainer>
            </FileUploadWrapper>
          </Grid>
        </>
      )}
      {isReUploading && isEmpty(censusFilesResponse) && (
        <div style={{ paddingTop: "23px" }}>
          <Divider variant="fullWidth" className="MuiDivider-webform" />
        </div>
      )}

      {!isEmpty(censusFilesResponse) &&
        (isLoadingChanges ? (
          <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <EmployeeTabs
            censusFilesResponse={censusFilesResponse}
            setCensusFilesResponse={setCensusFilesResponse}
            setIsLoadingChanges={setIsLoadingChanges}
          />
        ))}

      {isEmpty(censusFilesResponse) && isEmpty(employeeList) && <CensusNoEmployee />}

      {!isEmpty(employeeList) && isEmpty(censusFilesResponse) && (
        <>
          <Grid sx={{ width: "392px", pb: "23px", pt: "23px" }}>
            {!(isEmpty(employeeList) && isEmpty(tags)) && <SearchBar onSearch={handleSearch} />}
          </Grid>
          {isEmployeesLoading ? (
            <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <CustomTable<employeeDetails>
              columns={columns}
              rows={employeeList}
              rowsPerPageArray={[10, 20, 50]}
              totalCountRows={count}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
              handlePageChangeProp={handleChangePage}
            />
          )}
        </>
      )}

      {(isReUploading || !isEmpty(censusFilesResponse)) && (
        <Grid sx={{ display: "flex", justifyContent: "flex-end", height: "60px", gap: "16px", pt: "20px" }}>
          <Button sx={{ width: "100px" }} variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            sx={{ width: "100px" }}
            onClick={handleOnSave}
            variant="contained"
            disabled={isEmpty(censusFilesResponse) || isLoadingChanges}
          >
            Save
          </Button>
        </Grid>
      )}
      <CustomDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}></CustomDialog>
    </CensusFilePaperContainer>
  );
};

export default CensusEmployeesList;
