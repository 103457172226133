import axios from "axios";
import {
  getPartnerURL,
  getStatesURL,
  companyDetailsURL,
  getCompanyListURL,
  getEmployeeListURL,
  employeeDetailsURL,
  validateEmailURL,
  getuserDetailsURL,
  saveEmployerResourceURL,
  getEmployerResourceURL,
  getEmployeeListFilterURL,
  onFileUploadURL,
  saveCensusFileURL,
  removeCensusFileURL,
  removeCensusUserURL,
  resetCensusFileURL,
  reUploadCensusFileURL,
} from "../Config/APIConfig";

class ApiError extends Error {
  constructor(response) {
    super(response.data.errorMessage);
    this.response = response;
  }
}

axios.interceptors.response.use(
  (response) => {
    if (response.data.data === null && response.data.errorMessage) {
      throw new ApiError(response);
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const getConfig = (api) => {
  let apikey = "";
  switch (api) {
    case "employee":
      apikey = process.env.REACT_APP_EMPLOYEE_APIKEY;
      break;
    case "admin":
      apikey = process.env.REACT_APP_ADMIN_APIKEY;
      break;
    case "company":
      apikey = process.env.REACT_APP_COMPANY_APIKEY;
      break;
  }
  let config = {
    headers: {
      "x-api-key": apikey,
    },
  };
  return config;
};

export const getPartners = async () => {
  let response;
  try {
    response = await axios.get(getPartnerURL, getConfig("admin"));
    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const getStates = async () => {
  let response;
  try {
    response = await axios.get(getStatesURL, getConfig("admin"));
    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const saveCompanyDetails = async (req) => {
  let response;
  try {
    response = await axios.post(companyDetailsURL, req, getConfig("company"));
    if (response.status === 201 && response.data && response.data.id) {
      return {
        type: "success",
        message: "Success. Your changes have been saved",
        id: response.data.id,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      type: "error",
      message: error.response ? error.response.data : "Error. Your changes could not be saved. Please try again",
    };
  }
  return {
    type: "error",
    message: "Error. Your changes could not be saved. Please try again",
  };
};

export const updateCompanyDetails = async (req) => {
  let response;
  try {
    response = await axios.put(companyDetailsURL + "/" + req.id, req, getConfig("company"));
    if (response.status === 200 && response.data && response.data.id) {
      return {
        type: "success",
        message: "Success. Your changes have been saved",
      };
    }
  } catch (error) {
    console.log(error);
    return {
      type: "error",
      message: error.response ? error.response.data : "Error. Your changes could not be saved. Please try again",
    };
  }
  return {
    type: "error",
    message: "Error. Your changes could not be saved. Please try again",
  };
};

export const getCompanyList = async () => {
  let response;
  try {
    response = await axios.get(getCompanyListURL, getConfig("company"));
    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const getCompanyDetails = async (id) => {
  let response;
  try {
    response = await axios.get(companyDetailsURL + "/" + id, getConfig("company"));
    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

//employyees

export const getEmployeeList = async (id) => {
  let response;
  try {
    response = await axios.get(getEmployeeListURL + "/" + id, getConfig("employee"));
    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const getEmployeeDetails = async (company, id) => {
  let response;
  try {
    response = await axios.get(employeeDetailsURL + "/" + company + "/" + id, getConfig("employee"));
    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const updateEmployeeDetails = async (req) => {
  let response;
  try {
    response = await axios.put(employeeDetailsURL + "/" + req.companyId + "/" + req.id, req, getConfig("employee"));
    if (response.status === 200 && response.data && response.data.id) {
      return {
        type: "success",
        message: "Success. Your changes have been saved",
      };
    }
  } catch (error) {
    console.log(error);
    return {
      type: "error",
      message: error.response
        ? error.response.data.errorMessage
        : "Error. Your changes could not be saved. Please try again",
    };
  }
  return {
    type: "error",
    message: "Error. Your changes could not be saved. Please try again",
  };
};

export const addEmployeeDetails = async (req) => {
  let response;
  try {
    response = await axios.post(employeeDetailsURL + "/addEmployee", req, getConfig("employee"));
    if (response.status === 201 && response.data && response.data.id) {
      return {
        type: "success",
        message: "Success. Your changes have been saved",
      };
    }
  } catch (error) {
    console.log(error);
    return {
      type: "error",
      message: error.response
        ? error.response.data.errorMessage
        : "Error. Your changes could not be saved. Please try again",
    };
  }
  return {
    type: "error",
    message: "Error. Your changes could not be saved. Please try again",
  };
};

export const deleteEmployee = async (req) => {
  let response;
  try {
    response = await axios.delete(employeeDetailsURL, { data: { ...req } }, getConfig("employee"));
    if (response.status === 200) {
      return { type: "success", message: "Success. Employee has been deleted" };
    }
  } catch (error) {
    console.log(error);
    return {
      type: "error",
      message: error.response ? error.response.data : "Error. Your changes could not be saved. Please try again",
    };
  }
  return {
    type: "error",
    message: "Error. Your changes could not be saved. Please try again",
  };
};

export const sendEmail = async (req) => {
  let response;
  try {
    response = await axios.post(employeeDetailsURL + "/resendemail", req, getConfig("employee"));
    if (response.status === 200 && response.data && response.data.id) {
      return {
        type: "success",
        message: "Success. Login link has been sent to specified email",
      };
    }
  } catch (error) {
    console.log(error);
    return {
      type: "error",
      message: error.response ? error.response.data : "Error. Your changes could not be saved. Please try again",
    };
  }
  return {
    type: "error",
    message: "Error. Your changes could not be saved. Please try again",
  };
};

export const validateEmail = async (req) => {
  let response;
  try {
    response = await axios.post(validateEmailURL, req, getConfig("company"));
    if (response.status === 200 && response.data) {
      return { type: "success", ...response.data };
    }
  } catch (error) {
    console.log(error);
    return { type: "error" };
  }
  return {};
};

export const getUserDetails = async (token) => {
  let response;
  try {
    response = await axios.post(getuserDetailsURL, { token }, getConfig("company"));
    // response = { status: 200, data: { firstname: "Jane", lastname: "Doe", email: "Josh@gmail.com", companyId: "abcd" } }
    if (response.status === 200 && response.data) {
      return { type: "success", ...response };
    }
  } catch (error) {
    console.log(error);
    return { type: "error" };
  }
  return {};
};

export const saveEmployerResource = async (req) => {
  let response;
  try {
    response = await axios.post(saveEmployerResourceURL, req, getConfig("company"));
    if (response.status === 200) {
      return { type: "success", ...response };
    }
  } catch (error) {
    console.log(error);
    return { type: "error" };
  }
  return {};
};
export const fetchEmployerResource = async (id) => {
  let response;
  try {
    response = await axios.get(getEmployerResourceURL + "/" + id, getConfig("company"));
    if (response.status === 200 && response.data) {
      return { type: "success", ...response.data };
    }
  } catch (error) {
    console.log(error);
    return { type: "error" };
  }
  return {};
};

export const getEmployeeListFilter = async (data) => {
  let response;
  try {
    response = await axios.post(getEmployeeListFilterURL, data, getConfig("employee"));
    if (response.status === 200 && response.data) {
      return { type: "success", ...response.data };
    }
  } catch (error) {
    console.log(error);
    return { type: "error" };
  }
  return {};
};

export const onFileUpload = async (data, signal, onUploadProgress) => {
  let response;
  try {
    response = await axios.post(onFileUploadURL, data, {
      headers: { ...getConfig("employee").headers, "Content-Type": "multipart/form-data", accept: "*/*" },
      signal: signal,
      onUploadProgress,
    });
    if (response.status === 200 && response.data) {
      return { type: "success", data: response.data };
    }
  } catch (error) {
    console.log(error);
    return { type: "error" };
  }
  return {};
};

export const onSaveCensusFile = async (data) => {
  let response;
  try {
    response = await axios.post(saveCensusFileURL, { FilesToUpload: data }, getConfig("employee"));
    if (response.status === 200 && response.data) {
      return { type: "success" };
    }
  } catch (error) {
    console.log(error);
    return { type: "error" };
  }
  return {};
};

export const onRemoveCensusFile = async (data) => {
  let response;
  try {
    response = await axios.post(removeCensusFileURL, data, getConfig("employee"));
    if (response.status === 200 && response.data) {
      return { type: "success", data: response.data };
    }
  } catch (error) {
    console.log(error);
    return { type: "error" };
  }
  return {};
};

export const onRemoveCensusUser = async (data) => {
  let response;
  try {
    response = await axios.post(removeCensusUserURL, data, getConfig("employee"));
    if (response.status === 200 && response.data) {
      return { type: "success", data: response.data };
    }
  } catch (error) {
    console.log(error);
    return { type: "error" };
  }
  return {};
};

export const onResetCensusUpload = async (data) => {
  let response;
  try {
    response = await axios.delete(`${resetCensusFileURL}/${data.companyId}`, getConfig("employee"));
    if (response.status === 200) {
      return { type: "success" };
    }
  } catch (error) {
    console.log(error);
    return { type: "error" };
  }
  return {};
};

export const onReUploadCensusUpload = async (data) => {
  let response;
  try {
    response = await axios.post(`${reUploadCensusFileURL}`, data, getConfig("employee"));
    if (response.status === 200) {
      return { type: "success" };
    }
  } catch (error) {
    console.log(error);
    return { type: "error" };
  }
  return {};
};
